<template>
  <v-card class="pa-2 d-flex d-print-none non-printable" min-height="88vh"
          style="flex-direction: column; flex-wrap: nowrap; justify-content: flex-start">
    <v-dialog v-model="filterFormDialog"
              persistent
              max-width="600"
              v-if="document"
              class="d-print-none non-printable"
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-row>
            <v-col
                v-for="(col, idx) in filterFormData"
                :key="idx"
                cols="12"
                :md="col.cols"
            >
              <template v-if="col.component === 'v-checkbox'">
                <v-checkbox
                    color="success"
                    class="mt-0 pt-0"
                    hide-details
                    :required="false"
                    :label="col.label"
                    @change="onInputValueChange({field: col.key, value: $event.target.value})"
                    v-model="col.value"
                    style="margin-top: 0 !important; padding-top: 0 !important;"
                >
                </v-checkbox>
              </template>
              <template v-else>
                <component
                    :is="col.component"
                    :label="col.label"
                    :import-getter="col.import_getter ? { name: col.import_getter } : null"
                    :computed-action="col.computed_action"
                    :computed-getter="col.computed_getter"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :show-select-on-focus="col.import_getter !== null"
                    :select-button-object="col.select_button_object"
                    :show="true"
                    :required="false"
                    :field="col.key"
                    @inputValue="onInputValueChange"
                    v-model="col.value"
                />
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed small text color="secondary darken-1" @click="crudFilter" class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            Застосувати
          </v-btn>
          <v-btn depressed small text tile color="grey" @click="closeModal">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAccounting" width="1100" persistent class="d-print-none non-printable">
      <v-card style="overflow: hidden">
        <div class="top-header" style="position: relative; flex: 0;">
          <v-btn depressed small class="top-nav-button-close-1" @click.stop="closeAccounting">
            <v-icon size="21" color="grey darken-2">mdi-close</v-icon>
          </v-btn>
          <v-card-title class="pb-1">
            {{ accounting_document }}
          </v-card-title>
        </div>
        <div class="pt-1 pb-4 px-4">
          <table style="table-layout: fixed;">
            <thead>
            <tr id="header">
              <th rowspan="2" style="min-width: 80px; width: 80px !important;">
                Період
              </th>
              <th rowspan="2" style="min-width: 250px; width: 250px !important;">
                Документ
              </th>
              <th style="min-width: 60px; width: 60px !important;">
                Рахунок Дт
              </th>
              <th rowspan="2" style="min-width: 180px; width: 180px !important;">
                Аналітика Дт
              </th>
              <th style="min-width: 50px; width: 50px !important;">
                К-сть Дт
              </th>
              <th style="min-width: 60px; width: 60px !important;">
                Рахунок Кт
              </th>
              <th rowspan="2" style="min-width: 180px; width: 180px !important;">
                Аналітика Кт
              </th>
              <th style="min-width: 50px; width: 50px !important;">
                К-сть Кт
              </th>
              <th rowspan="2" style="min-width: 100px; width: 100px !important;">
                Сума
              </th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in accounting_items" :key="`table-row-${idx}`">
                <td style="min-width: 80px; width: 80px !important;">{{ item.date | formatDate }}</td>
                <td style="min-width: 250px; width: 250px; text-align: left">
                  {{ item.document_representation }}
                </td>
                <td style="min-width: 80px; width: 80px; text-align: center">
                  {{ item.debit_code }}
                </td>
                <td style="min-width: 180px; width: 180px">
                  <div v-if="item.debit_text_value_1 !== '[...<Не визначено>...]'">
                    {{ item.debit_text_value_1 }}
                  </div>
                  <div v-if="item.debit_text_value_2 !== '[...<Не визначено>...]'">
                    {{ item.debit_text_value_2 }}
                  </div>
                  <div v-if="item.debit_text_value_3 !== '[...<Не визначено>...]'">
                    {{ item.debit_text_value_3 }}
                  </div>
                </td>
                <td style="min-width: 50px; width: 50px; text-align: center">
                  <template v-if="item.debit_counted">
                    {{ item.count }}
                  </template>
                </td>
                <td style="min-width: 80px; width: 80px; text-align: center">
                  {{ item.credit_code }}
                </td>
                <td style="min-width: 180px; width: 180px">
                  <div v-if="item.credit_text_value_1 !== '[...<Не визначено>...]'">
                    {{ item.credit_text_value_1 }}
                  </div>
                  <div v-if="item.credit_text_value_2 !== '[...<Не визначено>...]'">
                    {{ item.credit_text_value_2 }}
                  </div>
                  <div v-if="item.credit_text_value_3 !== '[...<Не визначено>...]'">
                    {{ item.credit_text_value_3 }}
                  </div>
                </td>
                <td style="min-width: 50px; width: 50px; text-align: center">
                  <template v-if="item.credit_counted">
                    {{ item.count }}
                  </template>
                </td>
                <td style="min-width: 100px; width: 100px; font-weight: bold; text-align: center">
                  {{ item.sum | formatNumber }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-dialog>
    <div class="top-header d-print-none non-printable" style="position: relative; flex: 0;">
      <v-btn depressed small class="top-nav-button-full" @click.stop="changeFullWidth" v-if="modal_wrapper">
        <v-icon size="21" color="grey darken-2">
          {{ full_width ? 'mdi-minus' : 'mdi-checkbox-blank-outline' }}
        </v-icon>
      </v-btn>
      <v-btn depressed small class="top-nav-button-close" @click.stop="closeDialog" v-if="modal_wrapper">
        <v-icon size="21" color="grey darken-2">mdi-close</v-icon>
      </v-btn>
      {{ title }}
    </div>
    <v-row class="ma-0 d-print-none non-printable" style="flex: 1" >
      <v-col cols="12" :md="only_groups ? 12 : 3" v-if="hierarchy" class="grey lighten-4 tree-container">
        <div class="top-navigation">
          <MainModal
              :main="{ component: list_name, title: element_title }"
              :button="{
                    icon: false,
                    buttonHidden: true
                  }"
              :item="editObjectGroup"
              :modal="modal_group"
              :max-width="document ? 1400 : 800"
              :full_screen="full_screen"
              v-if="list_group_name && modal_group"
              @updateItemModal="closeEditGroupModal"/>
          <v-btn small color="secondary" depressed class="mr-2"
                 v-if="selectable && only_groups"
                 :disabled="!selection.length"
                 @click.stop="selectGroupByButton"
          >
            Вибрати
          </v-btn>
          <v-btn small color="secondary" dark depressed outlined class="mr-2" @click.stop="openEditGroupDialog">
            <v-icon size="18">mdi-pencil</v-icon>
          </v-btn>
          <v-btn small color="secondary" dark depressed outlined class="mr-2" @click.stop="openCreateGroupDialog">
            <v-icon size="18">mdi-plus</v-icon>
          </v-btn>
          <v-btn small color="secondary" dark depressed outlined @click.stop="openCopyDialog">
            <v-icon size="18">mdi-content-copy</v-icon>
          </v-btn>
        </div>
        <v-treeview
            dense
            hoverable
            activatable
            :items="groups"
            :open="open"
            :search="search"
            :filter="filter"
            :active.sync="selection"
            return-object
            color="grey darken-3"
            style="cursor: pointer !important;"
            class="group-elements"
        >
          <template v-slot:prepend="{ item }">
            <v-icon :color="item.root ? 'grey darken-2' : 'secondary'">mdi-folder</v-icon>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" :md="hierarchy ? 9 : 12" v-if="!only_groups" style="position: relative">
        <div class="top-navigation" :style="(this.filterItems || []).length ? 'height: 92px;' : 'height: 64px;'">
          <MainModal
              :main="{ component: list_name, title: element_title }"
              :button="{
                    icon: false,
                    buttonHidden: true
                  }"
              :item="Object.assign({}, editObject, add_object)"
              :modal="modal"
              :max-width="document ? 1400 : 800"
              :full_screen="full_screen"
              v-if="list_name && modal"
              @updateItemModal="closeEditModal"/>
          <MainModal
              :main="{ component: basic_create.list_name, title: basic_create.title }"
              :button="{
                    icon: false,
                    buttonHidden: true
                  }"
              :item="Object.assign({}, editObject, add_object)"
              :modal="basic_create_modal"
              :max-width="(documents_select[basic_create.list_name]?.documents || false) ? 1400 : 800"
              :full_screen="documents_select[basic_create.list_name]?.full_screen || false"
              v-if="basic_create.list_name && basic_create_modal"
              @updateItemModal="closeEditModalBasicCreate"/>
          <v-btn small color="secondary" depressed class="mr-2"
                 v-if="selectable && !multiple"
                 :disabled="!selected.id"
                 @click.stop="selectByButton"
          >
            Вибрати
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="success" depressed class="mr-2"
                     :disabled="!selected.id" outlined
                     v-bind="attrs"
                     v-on="on"
                     @click.stop="openEditDialog">
                <v-icon size="18">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="success" depressed outlined
                     v-bind="attrs"
                     v-on="on"
                     class="mr-2" @click.stop="openCreateDialog">
                <v-icon size="18">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Створити</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="success" depressed class="mr-2"
                     outlined :disabled="!selected.id"
                     v-bind="attrs"
                     v-on="on"
                     @click.stop="openCopyDialog">
                <v-icon size="18">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Копіювати</span>
          </v-tooltip>
          <v-tooltip bottom v-if="document">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="secondary" dark depressed class="mr-2" outlined
                     v-bind="attrs"
                     v-on="on"
                     @click.stop="filterFormDialog = true">
                <v-icon size="18">mdi-filter-outline</v-icon>
              </v-btn>
            </template>
            <span>Відкрити фільтр</span>
          </v-tooltip>
          <v-tooltip bottom v-if="document">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="secondary" dark depressed class="mr-2"
                     v-bind="attrs"
                     v-on="on"
                     outlined @click.stop="clearFilter">
                <v-icon size="18">mdi-filter-remove-outline</v-icon>
              </v-btn>
            </template>
            <span>Скинути фільтр</span>
          </v-tooltip>
          <v-tooltip bottom v-if="document && !hide_accounting">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="teal" depressed class="mr-2"
                     v-bind="attrs"
                     v-on="on"
                     :disabled="!selected.id" outlined
                     @click.stop="openAccounting">
                <v-icon size="18">
                  mdi-file-chart-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Проводки</span>
          </v-tooltip>
          <v-menu bottom :close-on-click="true"  v-if="document && getCreateBasics.length">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn small color="success" depressed class="mr-2"
                         :disabled="!selected.id" outlined
                         v-bind="attrs"
                         v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon size="18">
                      mdi-clipboard-check-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Створити на підставі</span>
              </v-tooltip>
            </template>

            <v-list dense nav>
              <v-list-item
                  v-for="(item, index) in getCreateBasics"
                  :key="index"
                  @click="openDocumentForBasicCreate(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-text-field
              v-model="search_text"
              hide-details
              single-line
              class="py-0 mt-2"
              color="grey"
              placeholder="Введіть текст для пошуку"
              append-icon="mdi-magnify"
              @input="doSearch"
          />
          <div class="filters mt-3 mb-1" v-if="document && (this.filterItems || []).length">
            <v-row>
              <v-col class="py-0">
                <v-chip-group>
                  <v-chip label close small
                          :color="'grey lighten-3'"
                          :close-icon="'mdi-minus-circle'"
                          @click="filterFormDialog = true"
                          @click:close="filterClick(item.field)"
                          v-for="(item, idx) in filterItems"
                          :key="idx"
                  >
                    <span>{{ `${item.name}: ${item.text}` }}</span>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="list-body" v-if="items.length"
             :style="multiple ?
                      (this.filterItems || []).length ? 'height: calc(100% - 243px);' : 'height: calc(100% - 215px);'
                      : (this.filterItems || []).length ? 'height: calc(100% - 152px);' : 'height: calc(100% - 124px);'">
          <div class="table-header" style="display: flex;">
            <div class="table-col" style="flex: 0 0 90px; padding-left: 2px; cursor: pointer;"
                 v-for="col in headers" :key="col.value || col.text || col.manual_id"
                 :style="getColStyle(col)"
                 @click.stop="headerClick(col.value)"
            >
              <template v-if="sorted_column(col.value) !== null && col.value !== ''">
                <v-icon size="18" class="mr-1" color="secondary darken-1">
                  {{ sorted_column(col.value) === true ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}
                </v-icon>
              </template>
              <template v-if="col.icon">
                <v-icon :size="col.icon.size ? col.icon.size : 22"></v-icon>
              </template>
              <template v-else>
                <template v-if="col.additionalField">
                  <template v-if="showAdditionalField">
                    {{ col.text }}
                  </template>
                </template>
                <template v-else>
                  {{ col.text }}
                </template>
              </template>
            </div>
          </div>
          <div class="table-body"
               style="overflow: auto;"
               :style="multiple ? 'height: calc(67vh - 167px)' : 'height: 67vh;'"
          >
            <div class="table-row"
                 style="display: flex; cursor: pointer; align-items: center; user-select: none !important; min-height: 30px"
                 v-for="row in filtered_items" :key="row.id"
                 :class="{'selected-row': (selected.id || 0) === row.id}"
                 @click="onItemClick(row)"
            >
              <div class="table-col"
                   v-for="col in headers" :key="`header-${row.id}-${col.value || col.manual_id}`"
                   :style="getColStyle(col)"
                   :class="getRowClass(col, row)"
              >
                <template v-if="col.icon">
                  <template v-if="col.icon.conducted">
                    <v-icon
                        :size="col.icon.size ? col.icon.size : 22"
                        :color="row.conducted ? 'success' : 'secondary'"
                    >
                      {{ row.conducted ? 'mdi-text-box-check-outline' : 'mdi-text-box-outline' }}
                    </v-icon>
                  </template>
                  <template v-else>
                    <template v-if="col.icon.column_true">
                      <v-icon :size="col.icon.size ? col.icon.size : 18"
                              :color="row[col.icon.column_true] ? col.icon.column_true_color : 'success'">
                        {{ col.icon.icon }}
                      </v-icon>
                    </template>
                    <template v-else>
                      <v-icon :size="col.icon.size ? col.icon.size : 18" color="success">{{ col.icon.icon }}</v-icon>
                    </template>
                  </template>
                </template>
                <template v-else-if="col.checkbox">
                  <v-simple-checkbox :value="row[col.value]" :ripple="false"></v-simple-checkbox>
                </template>
                <template v-else-if="col.date">
                  {{ row[col.value] | formatDate(col.date) }}
                </template>
                <template v-else-if="col.manual">
                  <template v-if="list_name === 'CHART_OF_ACCOUNTS_ELEMENTS'">
                    <template v-if="col.manual_id === 'actual'">
                      {{ getBalanceType(row.balance_type, true, false) }}
                    </template>
                    <template v-else-if="col.manual_id === 'subconto_1'">
                      {{ row.details.length > 0 ? getSubcontoType(row.details[0].detail_type) : '' }}
                    </template>
                    <template v-else-if="col.manual_id === 'subconto_2'">
                      {{ row.details.length > 1 ? getSubcontoType(row.details[1].detail_type) : '' }}
                    </template>
                    <template v-else-if="col.manual_id === 'subconto_3'">
                      {{ row.details.length > 2 ? getSubcontoType(row.details[2].detail_type) : '' }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_BANK_INCOME'">
                    <template v-if="col.manual_id === 'bank_income_operation'">
                      {{ getBankIncomeOperation(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_COMMISSIONING'">
                    <template v-if="col.manual_id === 'commissioning_document_operation'">
                      {{ getCommissioningOperation(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_BANK_OUTCOME'">
                    <template v-if="col.manual_id === 'bank_outcome_operation'">
                      {{ getBankOutcomeOperation(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_CASH_ORDER_OUTCOME'">
                    <template v-if="col.manual_id === 'cash_order_outcome_operation'">
                      {{ getCashOrderOutcomeOperation(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_CASH_ORDER_INCOME'">
                    <template v-if="col.manual_id === 'cash_order_income_operation'">
                      {{ getCashOrderIncomeOperation(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_SALARY_TO_PAY'">
                    <template v-if="col.manual_id === 'operation_to_pay'">
                      {{ get_salary_to_pay(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_CONTRACT_BY_CLN'">
                    <template v-if="col.manual_id === 'pay_type'">
                      {{ get_contract_pay_type_select(row.pay_type) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_SOCIAL_PRIVILEGE'">
                    <template v-if="col.manual_id === 'privilege_type'">
                      {{ getPrivilegeType(row.privilege_type) }}
                    </template>
                    <template v-if="col.manual_id === 'privilege_percent'">
                      {{ getPrivilegeType(row.privilege_type, true) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_ENTERING_BALANCES'">
                    <template v-if="col.manual_id === 'entering_balances_operation'">
                      {{ getEnteringBalancesOperation(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'FIXED_ASSETS_ELEMENTS'">
                    <template v-if="col.manual_id === 'asset_group'">
                      {{ getFixedGroup(row.asset_group) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'PERSONAL_ELEMENTS'">
                    <template v-if="col.manual_id === 'person_type'">
                      {{ get_person_type_select(row.person_type) }}
                    </template>
                    <template v-if="col.manual_id === 'person_full_name'">
                      <span :class="{'released': row.is_released}">
                        <template v-if="row.is_released">
                          {{ row.full_name }} ({{ row.release_date | formatDate }})
                        </template>
                        <template v-else>
                           {{ row.full_name }}
                        </template>
                      </span>
                    </template>
                    <template v-if="col.manual_id === 'full_time_type'">
                      {{ get_full_time_type_select(row.full_time_type) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'ACCRUAL_ELEMENTS'">
                    <template v-if="col.manual_id === 'accrual_type'">
                      {{ get_accrual_type(row.accrual_type) }}
                    </template>
                    <template v-if="col.manual_id === 'tax_group'">
                      {{ get_tax_group_select(row.tax_group) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'TAXES_ELEMENTS'">
                    <template v-if="col.manual_id === 'tax_group'">
                      {{ get_tax_group_select(row.tax_group) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'NATURAL_PERSON_ELEMENTS'">
                    <template v-if="col.manual_id === 'cripple_category'">
                      {{ get_cripple_select(row.cripple_category) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_TAX_BILL_INCOME'">
                    <template v-if="col.manual_id === 'tax_bill_income_operation'">
                      {{ getTaxBillIncomeOperation(row.operation) }}
                    </template>
                  </template>
                  <template v-else-if="list_name === 'DOCUMENT_TAX_BILL_OUTCOME'">
                    <template v-if="col.manual_id === 'tax_bill_outcome_operation'">
                      {{ getTaxBillOutcomeOperation(row.operation) }}
                    </template>
                  </template>
                </template>
                <template v-else-if="col.number_format">
                  {{ row[col.value] | formatNumber }}
                </template>
                <template v-else-if="col.additionalField">
                  <template v-if="showAdditionalField">
                    {{ row[col.value] }}
                  </template>
                </template>
                <template v-else>
                  {{ row[col.value] }}
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else
             class="pa-8 font-weight-medium success--text align-center d-flex justify-center align-center"
             :style="multiple ? 'height: calc(100% - 202px);' : 'height: calc(100% - 64px);'"

        >
          Не виялено жодного елементу для відображення
        </div>
        <div class="pay-table-pagination d-flex justify-start"
             v-if="items.length"
             style="position: absolute; left: 0; right: 0; background-color: #ffffff"
             :style="multiple ? 'bottom: 160px' : 'bottom: 0'"
        >
          <div class="pa-3 d-flex align-center justify-start">
            <v-btn text depressed icon class="mr-1"
                   @click.stop="pageStartOrEnd(true)"
                   :disabled="items_per_page > items.length || page === 1"
            >
              <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
            <v-btn text depressed icon
                   @click.stop="changePage(true)"
                   :disabled="page === 1"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mx-2">
              <v-chip>
                {{ `Сторінка: ${page} із ${pages}` }}
              </v-chip>
            </div>
            <v-menu top :close-on-click="true" :close-on-content-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" class="mr-2">
                  {{ `${items_per_page} на стор.` }}
                </v-chip>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in items_per_page_list"
                    :key="index"
                    @click="setItemsPerPage(item)"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn text depressed icon class="mr-1"
                   @click.stop="changePage(false)"
                   :disabled="items_per_page > items.length || page === pages"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn text depressed icon
                   @click.stop="pageStartOrEnd(false)"
                   :disabled="page === pages"
            >
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </div>
        </div>
        <div style="height: 140px" v-if="multiple" class="selectable-box">
          <div style="width: calc(100% - 64px)">
            <v-chip-group >
              <v-chip
                  v-for="item in selected_items" :key="item.id"
                  close
                  @click:close="deleteSelectedItem(item)"
              >
                {{ item.name || item.short_name || item.full_name || item.representaion }}
              </v-chip>
            </v-chip-group>
          </div>
          <div style="width: 64px">
            <v-btn depressed color="success" @click.stop="confirmSelectArray">
              ОК
            </v-btn>
          </div>

        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  getHeaders,
  getGetter,
  getAction,
  getActionFilter,
  buildDocumentFilterForm,
  getDocumentFilter,
  buildPayloadForRequest,
  buildFilterItems,
  sort_by,
  get_person_type_select,
  get_full_time_type_select,
  get_contract_pay_type_select,
  get_tax_group_select,
  get_cripple_select,
  create_on_the_basis, getDocumentsObject
} from "@/utils/accounting";
import {
  getSubcontoType,
  getBalanceType,
  getBankIncomeOperation,
  getBankOutcomeOperation,
  getTaxBillIncomeOperation,
  getFixedGroup,
  getCurrentDate,
  beginOfMonth,
  endOfMonth,
  get_accrual_type,
  getPrivilegeType,
  addMothsToStringDate,
  get_salary_to_pay,
  getCurrentDateTime,
  getCashOrderOutcomeOperation,
  getCashOrderIncomeOperation,
  getCommissioningOperation,
  getEnteringBalancesOperation, getEndOfYear, getTaxBillOutcomeOperation, debounce
} from "@/utils/icons"
import {mapGetters} from "vuex";
import accountingListAPI from "@/utils/axios/accounting/documents/list_select";

export default {
  name: "list_wrapper",
  props: {
    parent_modal: {
      type: Boolean,
      default() {
        return false
      }
    },
    dialog: {
      type: Boolean,
      default() {
        return false
      }
    },
    hierarchy: {
      type: Boolean,
      default() {
        return false
      }
    },
    selectable: {
      type: Boolean,
      default() {
        return false
      }
    },
    alternative_header_name: {
      type: String,
      default() {
        return ''
      }
    },
    list_name: {
      type: String,
      default() {
        return ''
      }
    },
    list_group_name: {
      type: String,
      default() {
        return ''
      }
    },
    store_getter: {
      type: String,
      default() {
        return ''
      }
    },
    store_group_getter: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default() {
        return 'Список...'
      }
    },
    element_title: {
      type: String,
      default() {
        return 'Елемент...'
      }
    },
    modal_wrapper: {
      type: Boolean,
      default() {
        return false
      }
    },
    only_groups: {
      type: Boolean,
      default() {
        return false
      }
    },
    action_props: {
      type: Object,
      default() {
        return {}
      }
    },
    add_object: {
      type: Object,
      default() {
        return {}
      }
    },
    owner_id: {
      type: Number,
      default: 0
    },
    action_filter: {
      type: String,
      default: ''
    },
    document: {
      type: Boolean,
      default() {
        return false
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    hide_accounting: {
      type: Boolean,
      default: false
    },
    full_screen: {
      type: Boolean,
      default() {
        return false
      }
    },
    sortedBy: {
      type: Array,
      default() {
        return []
      }
    },
    sync_action_props: {
      type: Boolean,
      default() {
        return false
      }
    },
    showAdditionalField: {
      type: Boolean,
      default() {
        return false
      }
    },
    form_id: {
      type: String,
      default() {
        return ''
      }
    }
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    textarea_input: () => import("@/components/accounting/input/document_text_area_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
  },
  computed: {
    ...mapGetters({
      reload_list: 'reload_list',
      accounting_settings: 'getAccountingGeneralSettings'
    }),
    getCreateBasics() {
      if (this.list_name) {
        const element = create_on_the_basis.find(i => i.document === this.list_name)
        return (element || {}).items || []
      }
      return []
    },
    headers() {
      return getHeaders(this.alternative_header_name || this.list_name)
    },
    filtered_items() {
      return this.items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    },
    groups() {
      const getter = getGetter(this.list_group_name)
      if (getter) {
        return this.$store.getters[getter]
      } else {
        return []
      }
    },
    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
  data() {
    return {
      showAccounting: false,
      accounting_items: [],
      accounting_document: '',
      documents_select: {},
      basic_create: {
        list_name: null,
        title: null
      },
      items: [],
      search_text: '',
      timer: null,
      activeItem: null,
      counter: 0,
      selected: {},
      selected_group: {},
      editObject: {},
      editObjectGroup: {},
      modal: false,
      modal_group: false,
      basic_create_modal: false,
      full_width: false,
      search: null,
      selection: [],
      open: [0],
      filterFormData: {},
      filterFormDialog: false,
      filterItems: [],
      localSortedBY: [],
      pages: 0,
      items_per_page: 13,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
      selected_items: [],
      debounceFn: null
    }
  },
  methods: {
    getDocumentsObject,
    openAccounting() {
      const payload = {
        document_type: this.list_name,
        document_id: this.selected.id
      }
      accountingListAPI.get_accounting(payload)
          .then(response => response.data)
          .then(data => {
            this.accounting_items = data.rows
            this.accounting_document = data.document
            this.showAccounting = true
          })
    },
    closeAccounting() {
      this.showAccounting = false
      this.accounting_items = []
      this.accounting_document = ''
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter(page_cash, id=null) {
      this.pages = Math.ceil(this.items.length / this.items_per_page)
      let page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.items.length) {
        this.pages -= 1
      }

      if (page_cash) {
        if (page_cash <= this.pages) {
          page = page_cash
        }
      }

      let selected

      if (id) {
        const item = this.items.find(i => i.id === id)
        let idx = -1
        if (item) {
          selected = Object.assign({}, item)
          idx = this.items.indexOf(item)
          const cur_page = Math.ceil((idx + 1) / this.items_per_page)

          if (cur_page !== page) {
            page = cur_page
          }
        }
      }

      this.page = page
      if (selected) {
        this.selected =  Object.assign({}, selected)
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    headerClick(payload) {
      if (!payload) return

      const obj = this.localSortedBY.find(i => i.key === payload)
      const its_charts = this.list_name === 'CHART_OF_ACCOUNTS_ELEMENTS'

      if (obj) {
        if (obj.desc) {
          this.localSortedBY.splice(this.localSortedBY.indexOf(obj), 1)
        }
        obj.desc = true
      } else {
        this.localSortedBY.push({key: payload, desc: false})
      }

      this.items = sort_by(this.items, this.localSortedBY || this.sortedBy, its_charts ? 'code' : '')
    },
    sorted_column(payload) {
      const obj = this.localSortedBY.find(i => i.key === payload)
      if (obj) {
        return obj.desc
      } else {
        return null
      }
    },
    onInputValueChange(payload) {
      if ('field' in payload) {
        this.filterFormData[payload.field]['represent'] = payload.text
      }
    },
    buildFilterItems,
    getBankIncomeOperation,
    getBankOutcomeOperation,
    getTaxBillIncomeOperation,
    getTaxBillOutcomeOperation,
    getCashOrderOutcomeOperation,
    getCashOrderIncomeOperation,
    getCommissioningOperation,
    getEnteringBalancesOperation,
    getSubcontoType,
    getBalanceType,
    getActionFilter,
    getFixedGroup,
    get_person_type_select,
    get_full_time_type_select,
    get_tax_group_select,
    get_accrual_type,
    get_contract_pay_type_select,
    get_cripple_select,
    getPrivilegeType,
    get_salary_to_pay,
    searchByText() {
      this.$nextTick(() => {
        const e = this.search_text
        const payload = {text: null}
        if (e && e.length >= 1) {
          payload.text = e
          this.fetchItems()
        }
        if (!e || e.length === 0) {
          this.fetchItems()
        }
      })

    },
    getSearchTextPayload() {
      const payload = {text: null}
      if (this.search_text && this.search_text.length >= 1) {
        payload.text = this.search_text
      }
      return payload
    },
    getRowClass(col, row) {
      if (col.manual) {
        if (this.list_name === 'CHART_OF_ACCOUNTS_ELEMENTS') {
          if (col.manual_id === 'actual') {
            return this.getBalanceType(row.balance_type, false, true)
          }
          return ''
          // if (col.manual_id === 'subconto_1') {
          //   return row.details.length > 0 ? getSubcontoType(row.details[0].detail_type) : ''
          // }
          // if (col.manual_id === 'subconto_2') {
          //   return row.details.length > 1 ? getSubcontoType(row.details[1].detail_type) : ''
          // }
          // if (col.manual_id === 'subconto_3') {
          //   return row.details.length > 2 ? getSubcontoType(row.details[2].detail_type) : ''
          // }
        }
      } else {
        return ''
      }
    },
    getColStyle(payload) {
      let style_string = ''
      if (!payload) style_string = 'flex: 1;'
      if (!payload.width) {
        style_string = 'flex: 1;'
      } else {
        style_string = `flex: 0 0 ${payload.width}px;`
      }
      if (payload.no_wrap) {
        style_string += 'white-space: nowrap; padding-right: 8px; text-overflow: ellipsis;'
      }
      if (this.headers.indexOf(payload) === 0) {
        style_string += 'padding-left: 5px;'
      }
      if (this.headers.indexOf(payload) === this.headers.length - 1) {
        if (payload.width) {
          style_string = `flex: 0 0 ${payload.width}px;`
        } else {
          style_string += 'flex: 1;'
        }
      }
      return style_string
    },
    selectByButton() {
      this.$emit('selectItem', Object.freeze(Object.assign({}, this.selected)))
      this.$emit('closeDialog')
      this.clearFilter()
      this.modal = false
    },
    confirmSelectArray() {
      this.$emit('selectItems', {form_id: this.form_id, table: JSON.parse(JSON.stringify(this.selected_items))})
      this.$emit('closeDialog')
      this.clearFilter()
      this.modal = false
    },
    selectGroupByButton() {
      if (!this.selection.length) return

      this.$emit('selectItem', Object.freeze(Object.assign({}, this.selection[0])))
      this.$emit('closeDialog')
      this.clearFilter()
      this.modal = false
    },
    closeDialog() {
      this.$emit('closeDialog')
      // this.items = []
      // this.filterItems = []
      this.selected = {}
      this.selected_group = {}

      localStorage.setItem(this.list_name, JSON.stringify(this.filterFormData))

      this.modal = false
    },
    changeFullWidth() {
      this.full_width = !this.full_width
      this.$emit('fullWidthDialog', this.full_width)
    },
    onItemClick(item) {
      this.counter++

      if (this.counter === 1) {
        this.activeItem = item
        this.selected = Object.assign({}, item)
        this.timer = setTimeout(() => {
          this.counter = 0
          this.activeItem = null
        }, 350)
      } else {
        if (this.activeItem === item) {
          if (this.selectable) {
            if (this.multiple) {
              const row = this.selected_items.find(i => i.id === item.id)

              if (!row) {
                this.selected_items.push(JSON.parse(JSON.stringify(item)))
              }

            } else {
              this.$emit('selectItem', Object.freeze(Object.assign({}, item)))
              this.$emit('closeDialog')
              this.modal = false
            }
          } else {
            if (!this.selected.id) return;
            this.editObject = Object.assign({}, this.selected)
            this.modal = true
          }
          this.counter = 0
          clearTimeout(this.timer)
        } else {
          clearTimeout(this.timer)
          this.counter = 0
        }
      }
    },
    openEditDialog() {
      if (!this.selected.id) return;
      this.editObject = Object.assign({}, this.selected)
      this.modal = true
    },
    openEditGroupDialog() {
      if (!this.selection.length) return;
      if (!this.selection[0].id) return;
      this.editObjectGroup = Object.assign({}, this.editObjectGroup)
      this.modal_group = true
    },
    openCreateDialog() {
      this.fillCreateObject(this.list_name)
      this.modal = true
    },
    openCreateGroupDialog() {
      this.editObjectGroup = {is_group: true}
      if (this.selection.length) {
        this.editObjectGroup.parent_id = this.selection[0].id
      }
      this.modal_group = true
    },
    openCopyGroupDialog() {
      if (!this.selection.length) return;
      this.editObjectGroup = Object.assign({}, this.editObjectGroup, {id: null, code: ''})
      this.modal_group = true
    },
    openCopyDialog() {
      if (!this.selected.id) return;
      this.editObject = Object.assign(
          {},
          this.selected,
          {id: null, code: '', number: '', conducted: false},
          {copy_id: this.selected.id}
      )
      this.modal = true
    },
    closeEditModal(payload) {
      this.modal = false

      if (payload.emit) {
        this.$emit('closeModalEmit', payload)
      }

      if (payload) {
        if (payload.payload) {
          if (payload.payload.emit) {
            this.$emit('closeModalEmit', payload)
          }
          if (payload.payload.fetch && !payload.payload.group) {
            const action_payload = {
              parent_id: null
            }
            if (this.selection.length) {
              action_payload.parent_id = this.selection[0].id
            }
            this.$store.dispatch(getAction(this.list_name), action_payload)
          }
        }
        if (payload.id && this.selected) {
          const row = this.items.find(item => item.id === payload.id)
          if (row) {
            this.activeItem = row
            this.selected = Object.assign({}, row)
          }
        }
      }
    },
    closeEditModalBasicCreate() {
      this.basic_create_modal = false

      this.basic_create.list_name = null
      this.basic_create.title = null

      // if (payload) {
      //   if (payload.payload) {
      //     if (payload.payload.fetch && !payload.payload.group) {
      //       const action_payload = {
      //         parent_id: null
      //       }
      //       if (this.selection.length) {
      //         action_payload.parent_id = this.selection[0].id
      //       }
      //       this.$store.dispatch(getAction(this.list_name), action_payload)
      //     }
      //   }
      //   if (payload.id && this.selected) {
      //     const row = this.items.find(item => item.id === payload.id)
      //     if (row) {
      //       this.activeItem = row
      //       this.selected = Object.assign({}, row)
      //     }
      //   }
      // }
    },
    closeEditGroupModal(payload) {
      this.modal_group = false
      if (payload) {
        if (payload.payload) {
          if (payload.payload.fetch && payload.payload.group) {
            this.$store.dispatch(getAction(this.list_group_name), this.element_title)
            this.$store.commit("RELOAD_NOMENCLATURE_SELECT", true)
          }
        }
      }
    },
    getDocumentFilter,
    buildDocumentFilterForm,
    buildPayloadForRequest,
    closeModal() {
      this.filterFormDialog = false
    },
    crudFilter() {
      this.fetchItems()
      this.filterFormDialog = false
      this.filterItems = buildFilterItems(this.filterFormData)
    },
    clearFilter() {
      const doc_filter = getDocumentFilter(this.list_name)
      const filter_form = buildDocumentFilterForm(doc_filter)

      this.filterFormData = Object.assign({}, filter_form)

      // Object.keys(this.filterFormData).forEach(key => {
      //   this.filterFormData[key]['value'] = null
      // })
      this.fetchItems()
      this.filterItems = buildFilterItems(this.filterFormData)
    },
    filterClick(payload) {
      if (payload) {
        this.filterFormData[payload]['value'] = null
        const obj = this.filterItems.find(i => i.field === payload)
        if (obj) {
          this.filterItems.splice(this.filterItems.indexOf(obj), 1)
        }
        this.fetchItems()
      }
    },
    fetchItems(action_filter = null, parent_id = null) {
      const getter = getGetter(this.list_name)
      const payload = buildPayloadForRequest(this.filterFormData)
      const act_filter = action_filter === null ? this.action_filter : action_filter
      if (getter) {
        const group_action = {}
        const its_charts = this.list_name === 'CHART_OF_ACCOUNTS_ELEMENTS'
        if (parent_id) {
          group_action.parent_id = parent_id
        }
        this.$store.dispatch(getAction(this.list_name), Object.assign(
            {},
            payload,
            group_action,
            this.action_props,
            this.getSearchTextPayload()
        ))
            .then(() => {
              const action_filter = this.getActionFilter(act_filter)
              let entries = this.$store.getters[getter]
              if (action_filter) {
                entries = (entries || []).filter(action_filter)
              }
              this.items = sort_by(entries, this.localSortedBY || this.sortedBy, its_charts ? 'code': '')
              this.setPageSplitter()
            })
            .catch(() => {
              this.items = []
              this.setPageSplitter()
            })
      } else {
        this.items = []
        this.setPageSplitter()
      }
    },
    fillCreateObject(list_name=null, basic_create_id=null, basic_create_type=null) {
      const default_bank_chart = this.accounting_settings?.default_bank_chart?.value || null
      const default_cash_chart = this.accounting_settings?.default_cash_chart?.value || null
      const default_customer_chart = this.accounting_settings?.default_customer_chart?.value || null
      const default_provider_chart = this.accounting_settings?.default_provider_chart?.value || null
      const default_pdv_credit_chart = this.accounting_settings?.default_pdv_credit_chart?.value || null
      const default_pdv_credit_not_confirmed_chart = this.accounting_settings?.default_pdv_credit_not_confirmed_chart?.value || null
      const default_advance_provider_chart = this.accounting_settings?.default_advance_provider_chart?.value || null
      const default_pdv_debit_chart = this.accounting_settings?.default_pdv_debit_chart?.value || null
      const default_pdv_debit_not_confirmed_chart = this.accounting_settings?.default_pdv_debit_not_confirmed_chart?.value || null
      const default_use_advances = this.accounting_settings?.use_advances?.value || false
      const default_advance_customer_chart = this.accounting_settings?.default_advance_customer_chart?.value || null
      const default_pdv_percent = this.accounting_settings?.default_pdv_percent?.value || null
      const pdv_without = this.accounting_settings?.pdv_without?.value || false
      const bank_without_accounting = this.accounting_settings?.bank_without_accounting?.value || null
      const default_currency = this.accounting_settings?.default_currency?.value || null
      const default_subdivision = this.accounting_settings?.default_subdivision_id?.value || null
      const default_null_chart = this.accounting_settings?.default_null_chart?.value || null
      const default_null_chart_calc = this.accounting_settings?.default_null_chart_calc?.value || null
      const default_bank_income_operation = this.accounting_settings?.default_bank_income_operation?.value || null
      const default_bank_outcome_operation = this.accounting_settings?.default_bank_outcome_operation?.value || null
      const default_cash_order_outcome_operation = this.accounting_settings?.default_cash_order_outcome_operation?.value || null
      const default_cash_order_income_operation = this.accounting_settings?.default_cash_order_income_operation?.value || null
      const default_bank_accounting = this.$store.getters.getDefaultBankAccount
      const prev_month = addMothsToStringDate(beginOfMonth(getCurrentDate()), -1)
      const prev_end_of_month_time = `${endOfMonth(prev_month)} 00:00:00.000000`
      const end_of_prev_year = `${getEndOfYear(addMothsToStringDate(beginOfMonth(getCurrentDate()), -12))} 00:00:00.000000`
      const default_warehouse = this.accounting_settings?.default_warehouse?.value || null
      const pdv_accounting = this.accounting_settings?.pdv_accounting?.value || false
      const signed_tax_person_id = this.accounting_settings?.signed_tax_person_id?.value || false

      const local_list_name = list_name ? list_name : this.list_name

      this.editObject = {}
      if (basic_create_id) {
        this.editObject.basic_create_id = basic_create_id
        this.editObject.basic_create_type = basic_create_type
      }
      if (this.hierarchy && this.editObjectGroup.id) {
        this.editObject.parent_id = this.editObjectGroup.id
      }
      if (this.sync_action_props && this.action_props.owner_id) {
        this.editObject.owner_id = this.action_props.owner_id
      }

      if (local_list_name === 'DOCUMENT_COMMISSIONING') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.operation = 'fixed_asset'
        this.editObject.warehouse_id = default_warehouse
        this.editObject.subdivision_id = default_subdivision
      }
      if (local_list_name === 'DOCUMENT_TAX_BILL_INCOME') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.operation = 'tax_bill'
        this.editObject.currency_id = default_currency
      }
      if (local_list_name === 'DOCUMENT_TAX_BILL_OUTCOME') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.operation = 'taxable_operation'
        this.editObject.currency_id = default_currency
        this.editObject.tax_enable = true
        this.editObject.include_to_erpn = true
        this.editObject.signed_person_id = signed_tax_person_id
      }
      if (local_list_name === 'DOCUMENT_LOW_COST_COMMISSIONING') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.warehouse_id = default_warehouse
        this.editObject.subdivision_id = default_subdivision
      }
      if (local_list_name === 'DOCUMENT_ENTERING_BALANCES') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.balance_date = end_of_prev_year
      }
      if (local_list_name === 'DOCUMENT_BANK_INCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.operation = default_bank_income_operation
        this.editObject.without_accounting = bank_without_accounting
        if (default_bank_accounting.id || default_bank_accounting.value) {
          this.editObject.bank_account_id = (default_bank_accounting.id || default_bank_accounting.value)
        }
        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id = default_pdv_debit_chart
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_debit_not_confirmed_chart
        if (pdv_accounting) {
          this.editObject.tax_id = default_pdv_percent
        } else {
          this.editObject.tax_id = pdv_without
        }

        if (this.editObject.without_accounting) {
          this.editObject.chart_of_account_id = default_null_chart
        } else {
          this.editObject.chart_of_account_id = default_bank_chart
        }

        if (this.editObject.operation === 'pay_from_customer') {
          if (this.editObject.without_accounting) {
            this.editObject.pay_chart_of_account_id = default_null_chart_calc
          } else {
            this.editObject.pay_chart_of_account_id = default_customer_chart
          }
        }
        if (this.editObject.operation === 'return_pay_from_provider') {
          this.editObject.pay_chart_of_account_id = default_provider_chart
        }
      }
      if (local_list_name === 'DOCUMENT_BANK_OUTCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.operation = default_bank_outcome_operation
        this.editObject.without_accounting = bank_without_accounting
        if (default_bank_accounting.id || default_bank_accounting.value) {
          this.editObject.bank_account_id = (default_bank_accounting.id || default_bank_accounting.value)
        }

        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id = default_pdv_credit_chart
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_credit_not_confirmed_chart
        if (pdv_accounting) {
          this.editObject.tax_id = default_pdv_percent
        } else {
          this.editObject.tax_id = pdv_without
        }

        if (this.editObject.without_accounting) {
          this.editObject.chart_of_account_id = default_null_chart
        } else {
          this.editObject.chart_of_account_id = default_bank_chart
        }

        if (this.editObject.operation === 'pay_from_provider') {
          if (this.editObject.without_accounting) {
            this.editObject.pay_chart_of_account_id = default_null_chart_calc
          } else {
            this.editObject.pay_chart_of_account_id = default_provider_chart
          }
        }
        if (this.editObject.operation === 'return_pay_from_customer') {
          this.editObject.pay_chart_of_account_id = default_customer_chart
        }
      }
      if (local_list_name === 'DOCUMENT_CASH_ORDER_OUTCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.operation = default_cash_order_outcome_operation
        this.editObject.without_accounting = bank_without_accounting

        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id = default_pdv_credit_chart
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_credit_not_confirmed_chart
        if (pdv_accounting) {
          this.editObject.tax_id = default_pdv_percent
        } else {
          this.editObject.tax_id = pdv_without
        }

        if (this.editObject.without_accounting) {
          this.editObject.chart_of_account_id = default_null_chart
        } else {
          this.editObject.chart_of_account_id = default_cash_chart
        }

        if (this.editObject.operation === 'pay_from_provider') {
          if (this.editObject.without_accounting) {
            this.editObject.pay_chart_of_account_id = default_null_chart_calc
          } else {
            this.editObject.pay_chart_of_account_id = default_provider_chart
          }
        }
        if (this.editObject.operation === 'return_pay_from_customer') {
          this.editObject.pay_chart_of_account_id = default_customer_chart
        }
      }
      if (local_list_name === 'DOCUMENT_CASH_ORDER_INCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.operation = default_cash_order_income_operation
        this.editObject.without_accounting = bank_without_accounting

        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id = default_pdv_debit_chart
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_debit_not_confirmed_chart
        if (pdv_accounting) {
          this.editObject.tax_id = default_pdv_percent
        } else {
          this.editObject.tax_id = pdv_without
        }

        if (this.editObject.without_accounting) {
          this.editObject.chart_of_account_id = default_null_chart
        } else {
          this.editObject.chart_of_account_id = default_cash_chart
        }

        if (this.editObject.operation === 'pay_from_customer') {
          if (this.editObject.without_accounting) {
            this.editObject.pay_chart_of_account_id = default_null_chart_calc
          } else {
            this.editObject.pay_chart_of_account_id = default_customer_chart
          }
        }
        if (this.editObject.operation === 'return_pay_from_provider') {
          this.editObject.pay_chart_of_account_id = default_provider_chart
        }
      }
      if (local_list_name === 'DOCUMENT_PERSON_TIMESHEET') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.month = prev_month
        this.editObject.date_start = this.editObject.month
        this.editObject.date_end = endOfMonth(this.editObject.month)
        this.editObject.fill_type = 'by_month'
      }
      if (local_list_name === 'DOCUMENT_SALARY_TO_PAY') {
        this.editObject.date = prev_end_of_month_time
        this.editObject.month = prev_month
        this.editObject.by_bank = true
        this.editObject.operation = 'salary'
      }
      if (local_list_name === 'DOCUMENT_SALARY_CHARGE') {
        this.editObject.date = prev_end_of_month_time
        this.editObject.month = prev_month
        this.editObject.currency_id = default_currency
      }
      if (local_list_name === 'DOCUMENT_ACT_WORK_PERFORMED_INCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.contractor_chart_of_account_id = default_provider_chart
        this.editObject.tax_chart_of_account_id = default_pdv_credit_chart
        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_credit_not_confirmed_chart
        if (default_use_advances) {
          this.editObject.advance_chart_of_account_id = default_advance_provider_chart
        } else {
          this.editObject.advance_chart_of_account_id = default_provider_chart
        }
      }
      if (local_list_name === 'DOCUMENT_INVOICE_WAREHOUSE_INCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.contractor_chart_of_account_id = default_provider_chart
        this.editObject.tax_chart_of_account_id = default_pdv_credit_chart
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_credit_not_confirmed_chart
        this.editObject.warehouse_id = default_warehouse
        this.editObject.tax_enable = pdv_accounting
        if (default_use_advances) {
          this.editObject.advance_chart_of_account_id = default_advance_provider_chart
        } else {
          this.editObject.advance_chart_of_account_id = default_provider_chart
        }
      }
      if (local_list_name === 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.contractor_chart_of_account_id = default_customer_chart
        this.editObject.tax_chart_of_account_id = default_pdv_debit_chart
        this.editObject.warehouse_id = default_warehouse
        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_debit_not_confirmed_chart
        if (default_use_advances) {
          this.editObject.advance_chart_of_account_id = default_advance_customer_chart
        } else {
          this.editObject.advance_chart_of_account_id = default_customer_chart
        }
      }
      if (local_list_name === 'DOCUMENT_BILL_OUTCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.warehouse_id = default_warehouse
        this.editObject.tax_enable = pdv_accounting
      }
      if (local_list_name === 'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.warehouse_id = default_warehouse
      }
      if (local_list_name === 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME') {
        this.editObject.currency_id = default_currency
        this.editObject.date = getCurrentDateTime()
        this.editObject.contractor_chart_of_account_id = default_customer_chart
        this.editObject.tax_chart_of_account_id = default_pdv_debit_chart
        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_debit_not_confirmed_chart
        if (default_use_advances) {
          this.editObject.advance_chart_of_account_id = default_advance_customer_chart
        } else {
          this.editObject.advance_chart_of_account_id = default_customer_chart
        }
      }
      if (local_list_name === 'DOCUMENT_ADVANCE_REPORT') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.warehouse_id = default_warehouse
        this.editObject.tax_chart_of_account_id = default_pdv_credit_chart
        this.editObject.tax_enable = pdv_accounting
        this.editObject.tax_chart_of_account_id_not_confirm = default_pdv_credit_not_confirmed_chart
      }
      if (local_list_name === 'DOCUMENT_WORK_TRANSFER') {
        this.editObject.date = getCurrentDateTime()
      }
      if (local_list_name === 'DOCUMENT_EMPLOYMENT') {
        this.editObject.date = getCurrentDateTime()
      }
      if (local_list_name === 'DOCUMENT_CONTRACT_BY_CLN') {
        this.editObject.date = getCurrentDateTime()
      }
      if (local_list_name === 'DOCUMENT_SOCIAL_PRIVILEGE') {
        this.editObject.date = getCurrentDateTime()
      }
      if (local_list_name === 'DOCUMENT_HOSPITAL_CHARGE') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.month = prev_month
        this.editObject.hospital_type = 'general_hospital'
      }
      if (local_list_name === 'DOCUMENT_VOCATION_CHARGE') {
        this.editObject.date = getCurrentDateTime()
        this.editObject.month = prev_month
        this.editObject.vocation_type = 'vocation_main'
      }
      if (local_list_name === 'DOCUMENT_RELEASE_WORK') {
        this.editObject.date = getCurrentDateTime()
      }
      if (local_list_name === 'DOCUMENT_ACCOUNTING_CLOSE_HOME_ACCOUNTING') {
        this.editObject.date = `${endOfMonth(prev_month)} 23:59:59`
        this.editObject.month = prev_month
        this.editObject.close_pays = true
        this.editObject.close_charge = true
      }
      if (local_list_name === 'DOCUMENT_ACCOUNTING_CLOSE_FINANCIAL_MONTH') {
        this.editObject.date = `${endOfMonth(prev_month)} 23:59:59`
      }
    },
    openDocumentForBasicCreate(payload) {
      this.basic_create.list_name = payload.component
      this.basic_create.title = payload.name

      this.fillCreateObject(this.basic_create.list_name, this.selected.id, this.list_name)
      this.basic_create_modal = true
    },
    deleteSelectedItem(payload) {
      const idx = this.selected_items.indexOf(payload)
      if (idx !== -1) {
        this.selected_items.splice(idx, 1)
      }
    },

    doSearch() {
      this.debounceFn()
    },
  },
  watch: {
    owner_id: {
      immediate: false,
      deep: false,
      handler() {
        if (this.sync_action_props) {
          const doc_filter = getDocumentFilter(this.list_name)
          const filter_form = buildDocumentFilterForm(doc_filter)
          this.filterFormData = Object.assign({}, filter_form)
          this.search_text = ''
          this.page = 1
          this.fetchItems()
        }
      }
    },
    action_props: {
      immediate: false,
      deep: false,
      handler(payload, payload_old) {
        const payload_n = payload || {}
        const payload_o = payload_old || {}

        if (JSON.stringify(payload_n) === JSON.stringify(payload_o)) return;

        this.fetchItems()
      }
    },
    action_filter: {
      immediate: false,
      handler(payload) {
        const doc_filter = getDocumentFilter(this.list_name)
        const filter_form = buildDocumentFilterForm(doc_filter)
        this.filterFormData = Object.assign({}, filter_form)
        this.search_text = ''
        this.page = 1
        this.fetchItems(payload)
      }
    },
    list_name: {
      immediate: true,
      handler(payload) {
        if (payload) {
          const doc_filter = getDocumentFilter(payload)
          const filter_form = buildDocumentFilterForm(doc_filter)
          this.documents_select = this.getDocumentsObject()

          const local_filter_form = localStorage.getItem(this.list_name)

          if (!this.sortedBy.length) {
            if (this.document) {
              this.localSortedBY.push(
                  {key: 'date', desc: false},
                  {key: 'number', desc: false},
              )
            } else {
              const its_charts = this.list_name === 'CHART_OF_ACCOUNTS_ELEMENTS'
              const code_header = this.headers.find(i => i.value === 'code')
              const short_name_header = this.headers.find(i => i.value === 'code')
              const full_name_header = this.headers.find(i => i.value === 'code')
              const name_header = this.headers.find(i => i.value === 'code')

              if (!its_charts) {
                if (code_header) {
                  this.localSortedBY.push({key: 'code', desc: false})
                }
                if (short_name_header) {
                  this.localSortedBY.push({key: 'short_name', desc: false})
                } else {
                  if (full_name_header) {
                    this.localSortedBY.push({key: 'full_name', desc: false})
                  } else {
                    if (name_header) {
                      this.localSortedBY.push({key: 'name', desc: false})
                    }
                  }
                }
              }
            }
          }

          this.items = []
          this.filterItems = []
          this.selected_items = []

          if (!local_filter_form || local_filter_form === '{}') {
            this.filterFormData = Object.assign({}, filter_form)
          } else {
            this.filterFormData = Object.assign({}, JSON.parse(local_filter_form))
            this.filterItems = buildFilterItems(this.filterFormData)
          }

          this.search_text = ''
          this.page = 1
          this.showAccounting = false
          this.fetchItems()
        }
      }
    },
    list_group_name: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.$store.dispatch(getAction(payload), this.element_title)
        }
      }
    },
    selection(value) {
      if (value.length) {
        const getter = getGetter(this.list_name)
        this.editObjectGroup = Object.assign({}, value[0])
        if (getter) {
          if (!this.only_groups) {
            this.fetchItems(null, value[0].id)
          }
        } else {
          this.items = []
          this.setPageSplitter()
        }

      } else {
        this.editObjectGroup = {}
      }
    },
    reload_list: {
      immediate: false,
      handler(payload) {
        if (payload && payload.getter) {
          const its_charts = this.list_name === 'CHART_OF_ACCOUNTS_ELEMENTS'
          const prev_current_page = this.page

          if (getGetter(this.list_name) === payload.getter) {
            if (!payload.select) {
              let entries = this.$store.getters[payload.getter]
              const action_filter = this.getActionFilter(this.action_filter)
              if (action_filter) {
                entries = (entries || []).filter(action_filter)
              }
              this.items = sort_by(entries, this.localSortedBY || this.sortedBy, its_charts ? 'code' : '')
              this.setPageSplitter(prev_current_page, payload.id || null)
              this.showAccounting = false
              this.accounting_items = []
              this.accounting_document = ''

              if (payload.all) {
                this.fetchItems()
              }
            } else {
              this.$emit('onReload', true)
            }
          }
        }
      }
    },
    dialog(payload) {
      if (payload) {
        this.selected_items = []
        this.selected = {}
      }
    }

  },
  created() {
    this.debounceFn = debounce(() => this.searchByText(), 800)
  }
}
</script>

<style scoped lang="scss">
$border-color: #cbcbcb;

.table-col {
  padding: 3px 3px;
  font-size: 0.82rem;
  max-height: 39px;
  overflow: hidden;
  line-height: 1rem;
}

.table-header {
  .table-col {
    padding: 5px 3px;
    background-color: #e3e3e3;
    font-weight: 500;
    font-size: 0.8rem;
    min-height: 28px;
  }
}

.top-navigation {
  margin-bottom: 10px;

}

.top-header {
  font-weight: 500;
  font-size: 1.18rem;
  position: relative;
  margin-bottom: 4px;
  padding-top: 8px;
  padding-left: 12px;

  .top-nav-button-close {
    position: absolute;
    right: 5px;
    top: -4px
  }

  .top-nav-button-close-1 {
    position: absolute;
    right: 5px;
    top: 4px
  }

  .top-nav-button-full {
    position: absolute;
    right: 60px;
    top: -4px
  }
}

.selected-row {
  background-color: #f5f5f5;

  .table-col {
    font-weight: 500 !important;
  }
}

.table-row:hover {
  background-color: rgba(255, 238, 220, 0.64);
}

.tree-container {
  :deep(.v-treeview--dense .v-treeview-node__root) {
    min-height: 30px !important;

    .v-treeview-node__content {
      .v-treeview-node__label {
        font-size: .88rem !important;
      }
    }
  }
}

table, th, td {
  border: 1px solid #cbcbcb;
  border-collapse: collapse;
}

th {
  background: #f3f3f3;
  font-weight: 500 !important;
  padding: 8px !important;
}

th, td {
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  width: 100px;
}

.released {
  color: darkgrey;
  text-decoration: line-through;
}

.selectable-box {
  padding: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;

  .selectable-box-item {
    padding: 4px;
    background-color: white;
    border-radius: 12px;
  }
}
</style>